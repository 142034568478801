/*-----------------------------------------------------------------------------------
/*
/* Init JS
/*
-----------------------------------------------------------------------------------*/

 jQuery(document).ready(function() {



/*----------------------------------------------------*/
/*	gmaps
------------------------------------------------------*/

	var map;

	// // main directions
	map = new GMaps({
		el: '#gmaps', 
		lat: -38.8963077, 
		lng: -68.0314633, 
		zoom: 14, 
		zoomControl : true,
		zoomControlOpt: { 
			style : 'SMALL', 
			position: 'TOP_CENTER' 
		}, 
		panControl : false, 
		scrollwheel: false
	});

   // add address markers
	map.addMarker({ 
		lat: -38.8963077, 
		lng: -68.0314633, 
		title: 'SANOVO GREENPACK - Planta Gualeguaychú', 
		infoWindow: { 
			content: '<p>Parque Industrial de Gualeguaychú, calle 1 Lote 32 y 33, Entre Ríos, República Argentina.</p>'
		}
	});
});